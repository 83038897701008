import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Main from '../views/Main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      requireAuth: true
    },
    children:[
      {
        path:'/home',
        name:'Home',
        component:Home
      },
    ],
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   let token = window.localStorage.getItem('token')
//   let user = JSON.parse(window.localStorage.getItem('user'))
//   if (user && user.technology_partner) {
//     if (to.path !== '/tech-analytics') next({name: 'TechAnalytics'})
//     else next()
//   }
//   else {
//     if (to.meta.requireAuth !== false && !token) next({ name: 'Login' })
//     else if (to.path === '/') next({name: 'Checks'})
//     else next()
//   }
// })

export default router
