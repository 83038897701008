<template>
  <div id="app">
    <v-overlay
        z-index="100"
        :value="overlay"
    >
      <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
      />
    </v-overlay>
    <v-app id="inspire">
      <v-card shaped class="rounded-card mx-auto mt-5 mb-5">
        <v-card-title class="justify-center">
            <span class="text-h5">
              Voter Data Extraction
            </span>
        </v-card-title>
        <v-card-text>
          <v-col
              cols="12"
              md="12"
          >
            <v-file-input
                accept="application/pdf"
                placeholder="Upload File"
                prepend-icon="mdi-file"
                label="Click Here to Upload File"
                v-model="voter_file"
            ></v-file-input>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12" md="12" class="text-center">
            <v-btn
                class="mx-auto"
                dark
                color="blue"
                @click="extractData"
            >
              <v-icon>description</v-icon>
              Extract Data
            </v-btn>
          </v-col>
        </v-card-actions>
        <v-col v-if="errMsg" cols="12" md="12" class="text-center">
          <p class="red--text">{{ errMsg }}</p>
        </v-col>
      </v-card>
      <v-btn
          v-if="csv_link !== null"
          depressed
          color="green"
          class="mx-auto white--text mb-4"
          @click="downloadFile"
      >
        <v-icon>download</v-icon>
        Download Data
      </v-btn>
      <v-card style="width: 90%" class="mb-5 mx-auto" v-if="printingDate || blockCode || totalCount">
        <v-row dense>
          <v-card-subtitle class="font-weight-bold">File Information:</v-card-subtitle>
        </v-row>
        <v-row dense>
          <v-col md="3"><v-card-text>Block Code: {{ blockCode }}</v-card-text></v-col>
          <v-col md="2"><v-card-text>Total Count: {{ totalCount }}</v-card-text></v-col>
          <v-col md="2"><v-card-text>Male Count: {{ maleCount }}</v-card-text></v-col>
          <v-col md="2"><v-card-text>Female Count: {{ femaleCount }}</v-card-text></v-col>
          <v-col md="3">
            <v-row dense>
              <v-col md="6"><v-card-text class="text-right">Printing Date: </v-card-text></v-col>
              <v-col md="6"><v-card-text class="rtl text-left">{{ printingDate }}</v-card-text></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="voters.length > 0" class="mx-auto" style="width: 90%;">
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="voters"
              item-key="name"
              class="elevation-1">
            <template v-slot:body="{ items, headers }">
              <tbody>
              <tr v-for="(item,idx,k) in items" :key="idx">
                <td v-for="(header,key) in headers" :key="key" :class="header.value === 'address'? 'rtl': ''">
                  <v-edit-dialog
                      :return-value.sync="item[header.value]"
                      @save="save(idx)"
                      @cancel="cancel"
                      @open="open"
                      @close="close"
                      large
                  > {{item[header.value]}}
                    <template v-if="!item[header.value] && item[header.value+'_image']">
                      <v-img :src="item[header.value+'_image']">
                      </v-img>
                    </template>
                    <template v-slot:input>
                      <v-text-field
                          v-model="item[header.value]"
                          label="Edit"
                          single-line
                          :class="header.value === 'address'? 'rtl': ''"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-app>
  </div>
</template>
<script>

export default {
  computed: {

  },
  methods: {
    save(idx) {
      this.overlay = true
      let voterData = this.voters[idx]
      delete voterData.name_image
      delete voterData.father_name_image
      this.$http.patch(`/update-voter/${voterData.cnic}/`, voterData).then(res => {
        this.overlay = false
      })
    },
    cancel() {},
    open() {},
    close() {},
    // updateVoterChanges() {
    //   this.overlay = true
    //   this.$http.put("/voters/", this.updated_voters).then(res => {
    //     this.updated_voters = []
    //     this.overlay = false
    //   })
    // },
    extractData() {
      this.errMsg = this.maleCount = this.femaleCount = this.totalCount = this.printingDate = this.blockCode = ""
      this.csv_link = null
      if (this.voter_file) {
        this.overlay = true
        let formData = new FormData();
        formData.append("voter_file", this.voter_file)
        this.$http.post("/extract-voter-data/", formData).then(res => {
          if (res.data.voters.length > 0) {
            this.voters = res.data.voters
            this.csv_link = res.data.csv_link
            this.maleCount = res.data.male_count
            this.femaleCount = res.data.female_count
            this.totalCount = res.data.total_count
            this.printingDate = res.data.printing_date
            this.blockCode = res.data.block_code
          } else {
            this.errMsg = "No data found."
          }
          this.overlay = false
        }).catch(e => {
          this.errMsg = e
          this.overlay = false
        })
      } else {
        this.errMsg = "Please upload valid PDF file."
      }
    },
    downloadFile() {
      if (this.csv_link !== null) {
        window.location.href = this.csv_link;
      }
    }
  },
  data() {
    return {
      voters: [],
      csv_link: null,
      errMsg: "",
      maleCount: "",
      femaleCount: "",
      totalCount: "",
      printingDate: "",
      blockCode: "",
      voter_file: null,
      overlay: false,
      headers: [
        {
          text:"Sr. No. ",
          align: "start",
          filterable: true,
          sortable: true,
          value: 'serial_no',
        },
        {
          text: 'Gharana No',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'gharana_no',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'name',
        },
        {
          text: 'Father Name',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'father_name',
        },
        {
          text: 'CNIC',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'cnic',
        },
        {
          text: 'Age',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'age',
        },
        {
          text: 'Address',
          align: 'start',
          sortable: true,
          filterable:false,
          value: 'address',
        }
      ]
    }
  },
}
</script>

<style scoped>
.rounded-card {
  border-radius: 24px !important;
  width: 40%;
}
.v-list.pending{
  height: 300px;
  overflow-y:auto
}
.user-btn {
  background: transparent !important;
  color: white !important;
}
.my-menu {
  margin-top: 40px;
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: fixed;
  content: "";
  top: 12.2%;
  right: 10.21%;
  transform: translateY(-100%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
  display: none;
}
.my-menu-hide {
  display: none;
}
.rtl {
  direction: rtl;
}
</style>
