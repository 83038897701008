import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios';
import { VueMaskDirective } from 'v-mask';

let headers = {}
let token = localStorage.getItem('token')
if (token !== null) {
  headers = {
    'Authorization': `token ${token}`
  }
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})
console.log(process.env.VUE_APP_BASE_URL)
Vue.prototype.$http = instance

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

instance.interceptors.response.use(function (response) {
  return response
}, // function (error) {
//   console.log(error)
//   if (error.response && error.response.status === 401) {
//     localStorage.removeItem('token')
//     localStorage.removeItem('user')
//     // store.dispatch('logout')
//     window.location.replace('/login')
//   }
//   return Promise.reject(error)
// }
)

Vue.directive('mask', VueMaskDirective);
